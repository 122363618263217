if($('#menu .mobile-menu-btn').length > 0) {

    $('#menu .mobile-menu-btn').click(function () {
        var menu = $('#menu');

        if(menu.hasClass('active')) {
            menu.removeClass('active');
        } else {
            menu.addClass('active');
        }
    });

    if($(window).width() < 961) {
        $('#menu a').click(function () {
            var menu = $('#menu');

            if(menu.hasClass('active')) {
                menu.removeClass('active');
            }
        });
    }
}