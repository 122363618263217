if($('.screen-list').length > 0) {

    $('.screen-list ol li').click(function () {
        $(this).closest('ol').find('li').removeClass('active');
        $(this).addClass('active');

        var img_url = $(this).attr('data-img');
        if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
            img_url = $(this).attr('data-img-dark');
        }

        $(this).closest('.info').find('img').attr('src', img_url);
    });

    $('.screen-list ol li.active').each(function () {
        if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
            $(this).closest('.info').find('img').attr('src', $(this).attr('data-img-dark'));
        }
    });
}